import { createSlice } from '@reduxjs/toolkit';

const signSlice = createSlice({
	name: 'sign',
	initialState: {
		isElementToRender: false,
		removedState: null,
		signWarning: null,

		isRerenderGallery: false,
		isRerenderApproved: false,
		isRerenderActiveOrder: false,
		isSignViewerLoading: false,
		isRefetchGallery: false,
	},
	reducers: {
		setElementsToRender(state, action) {
			const currState = state;
			currState.isElementToRender = action.payload;
		},
		removeSelectedState(state, action) {
			const currState = state;
			currState.removedState = action.payload;
		},
		setSignWarning(state, action) {
			const currState = state;
			currState.signWarning = action.payload;
		},
		setIsRerender(state, action) {
			const currState = state;
			const { key, value } = action.payload
			currState[key] = value;
		}
	},
});

export const signActions = signSlice.actions;
export default signSlice;
