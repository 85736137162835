import { lazy } from 'react';
import { route } from './RouteName';

const CustomerList = lazy(() => import('../components/CustomerList/CustomerList'));
// const OrderList = lazy(() => import('../components/OrderList/OrderList'));
const ReadyToPrintList = lazy(() => import('../components/ReadyToPrintList/ReadyToPrintList'));
const ProjectStatus = lazy(() => import('../components/ProjectStatus/ProjectStatus'));
const Archive = lazy(() => import('../components/Archive/Archive'));
const ProjectDetail = lazy(() => import('../components/ProjectStatus/ProjectDetail/ProjectDetail'));
const NotOrdered = lazy(() => import('../components/NotOrdered/NotOrdered'));

const projectStyle = { minHeight: 'calc(100vh - 78px)', height: '100%' }

export const projectRoutes = [
    {
        path: route.customerList,
        element: <CustomerList />
    },
    // {
    //     path: route.seeOrder,
    //     element: <OrderList />
    // },
    {
        path: route.readyToPrint,
        element: <ReadyToPrintList />,
        groups: ['Superuser']
    },
    {
        path: route.archive,
        element: <Archive />
    },
    {
        path: route.notOrdered,
        element: <NotOrdered />
    },
    {
        path: route.projects,
        element: <ProjectStatus />,
        style: projectStyle
    },
    {
        path: `${route.projects}/detail/:projectId/`,
        element: <ProjectDetail />,
        style: projectStyle
    },
    {
        path: `${route.projects}/detail/:projectId/:signId`,
        element: <ProjectDetail />,
        style: projectStyle
    },
    // {
    //     path: route.deliveries,
    //     element: <ProjectStatus />,
    //     style: projectStyle
    // },
    // {
    //     path: `${route.deliveries}/detail/:projectId/`,
    //     element: <ProjectDetail />,
    //     style: projectStyle
    // },
    // {
    //     path: `${route.deliveries}/detail/:projectId/:signId`,
    //     element: <ProjectDetail />,
    //     style: projectStyle
    // },
]
