export const mountingType = [
	{
		id: 'endeholder',
		label: 'Endeholder',
		type: 'global',
	},
	{
		id: 'toppholder',
		label: 'Toppholder',
		type: 'global',
	},
	{
		id: 'uten hulling',
		label: 'Uten Hulling',
		type: 'global',
	},
	{
		id: 'veggmontert',
		label: 'Veggmontert',
		type: 'global',
	},
	{
		id: 'stolpe',
		label: 'Stolpe',
		type: ['rectangle', 'single'],
	},
	{
		id: 'vegg',
		label: 'Vegg',
		type: ['rectangle', 'single'],
	},
	{
		id: 'tresstolpe',
		label: 'Trestolpe',
		type: ['rectangle', 'single'],
	},
];
