export const route = {
	settings: 'settings',
	font: 'font',
	colors: 'colors',
	icons: 'icons',
	auth: 'auth',
	login: 'login',
	resetPassword: 'reset-password',
	user: 'user',
	customId: ':id',
	openSign: 'open-sign',
	customerList: 'customer-list',
	seeOrder: 'see-order',
	statuses: 'statuses',
	readyToPrint: 'ready-to-print',
	archive: 'archive',
	projects: 'projects',
	deliveries: 'deliveries',
	signTypeColor: 'sign-type-color',
	error: 'error',
	region: 'region',
	dashboard: 'dashboard',
	notOrdered: 'not-ordered',
};
